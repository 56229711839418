import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
// import { GatsbyImage } from "gatsby-plugin-image"

const NotFoundPage = ({ data, location }) => {
  // const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={"404: Not Found"}>
      <Seo title={"404: Not Found"} />
      <Container
        maxWidth="string"
        disableGutters
        sx={{
          maxWidth: "692px",
          "@media (max-width: 600px)": {
            px: "1.5rem",
          },
        }}
      >
        <Typography>
          这里空空如也，就像我的钱包一样。
          <img
            src="http://3650000.xyz/api/360.php?cid=6"
            style={{ borderRadius: "8px", marginTop: "10px" }}
            alt="404图片"
          />
        </Typography>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
